import axios from 'axios';
import api from './api';
export default {
  getProduct: async (data) => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_Btpbrandlabs_Api+`get-products/${data}`,
      headers: {
        'accesstoken': sessionStorage.getItem('accessToken'),
      },
    };
    return axios
        .request(config)
        .then((response) => {
          console.log(response);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
  },
  getSection: async (data)=>{
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_Btpbrandlabs_Api +`get-section/${data}`,
      headers: {
        'accesstoken': sessionStorage.getItem('accessToken'),
      },
    };
    return axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
  },

  getPagesAll: async ()=>{
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_Btpbrandlabs_Api + 'get-pages-all',
      headers: {
        accesstoken: process.env.REACT_APP_Get_AllData_Accesstoken,
      },
    };
    return axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
  },
  resetPassword: async (data)=>{
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_Btp_Global_Api+
      'BptglobalUser/global_settings_user_forgot_password',
      headers: {
        'Accesstoken': process.env.REACT_APP_Global_Token,
        'Content-Type': 'text/plain',
      },
      data: data,
    };
    return axios
        .request(config)
        .then((response) => {
          console.log(response);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
  },
  userSignIn: async (data) =>{
    try {
      const response = api
          .post('/BptglobalUser/global-settings-user-signin', data, {
            headers: {
              'accesstoken': process.env.REACT_APP_Global_Token,
              'Content-Type': 'application/json',
            },
          });
      return response;
    } catch (err) {
      console.log(err);
      return;
    }
  },

  userRegister: async (data)=>{
    try {
      const response = api
          .post('/BptglobalUser/global_settings_user_add', data, {
            headers: {
              'accesstoken': process.env.REACT_APP_Global_Token,
              'Content-Type': 'application/json',
            },
          });
      return response;
    } catch (err) {
      console.log(err);
      return;
    }
  },
};
