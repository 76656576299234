import React, {useState} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import BrandApi from '../utils/brandapi.js';
import Swal from 'sweetalert2';

const Forget = (prop) => {
  const [formData, setFormData] = useState({});
  const handleReset = async (e) => {
    e.preventDefault();
    const data = `{\n    'email' : '${formData.email}',\n    'reset_link' : 'https://www.beingtheparent.com/reset-password/'\n}`;
    await BrandApi.resetPassword(data)
        .then((response) => {
          if (response.status==true) {
            prop.setShowReset(true);
            prop.setForgetShow(false);
          } else if (response.status==false) {
            Swal.fire({
              icon: 'error',
              text:
            response.data.message,
              timer: 1000,
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  return (
    <Modal
      backdrop='static'
      show={prop.forgetShow}
      onHide={() => {
        prop.setForgetShow(false);
      }}
    >
      <Modal.Header closeButton className='border-0 '
        style={{marginTop: '-30px'}}></Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleReset}>
          <Form.Group className='mb-4' controlId='formBasicEmail'>
            <Form.Label>Forgot Password</Form.Label>
            <p className='congoP'>
                            Please put the email below to reset the link
            </p>
            <Form.Control
              type='email'
              placeholder='Enter email'
              name='email'
              id='email'
              required
              onChange={handleChange}
              value={formData.email}
            />
          </Form.Group>
          <Button
            variant='danger'
            type='submit'
            className='col-12 customPopupBtn'
          >
                        Submit
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className='border-0'></Modal.Footer>
    </Modal>
  );
};

export default Forget;
