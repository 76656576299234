import React from 'react';
import ImageCarousel from '../components/Banners';
import BrandsForYou from '../components/BrandsForYou';
import BuyingGuide from '../components/BuyingGuide';
import RelatedArticle from '../components/RelatedArticle';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import '../assets/css/desktop.css';
const Home = () => {
  return (
    <Container id='' className='px-0 home-container'>
      <Card className='first-card border-bottom'>
        <ImageCarousel />
      </Card>
      <BrandsForYou />
      <BuyingGuide />
      <RelatedArticle />
    </Container>
  );
};

export default Home;
