import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
// import Message from './Message';
import {useParams} from 'react-router-dom';
import Login from './Login';
import BrandArticle from '../components/BrandArticle';
import BrandsProducts from '../components/BrandsProducts';
import BrandPhotos from '../components/BrandsPhotos';
import BrandApi from '../utils/brandapi.js';

const Brands = () => {
  // for message popup comment
  // const [showMessage, setShowMessage] = useState(false);
  // const [userId, setUserID] = useState('');
  // const [userName, setUserName] = useState();
  // const [loginUserId, setLoginUserId] = useState();
  // const [email, setEmail] = useState();
  // const [page_idValue, setPageIDValue] = useState();

  const [loginPopup, setLoginPopup] = useState(false);
  const params = useParams();
  const {name} = params;
  const [arrayData, setArrayData] = useState([]);
  const getData = async () => {
    await BrandApi.getPagesAll()
        .then((response) => {
          setArrayData(response.data);
        })
        .catch((error) => {
          console.log('API error:', error);
        });
  };

  // for message popup comment
  // const getUserDeatils = () => {
  //   const data = '';
  //   const config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url:
  //       process.env.REACT_APP_Btp_Global_Api +
  //       '/Btpmobileapi/getuser_details',
  //     headers: {
  //       'accesstoken': sessionStorage.getItem('accessToken'),
  //       'Content-Type': 'application/json',
  //       'Cookie': 'ci_session=efid96boq2kgg6cgetfid3vqfesc4kp8',
  //     },
  //     data: data,
  //   };
  //   axios
  //       .request(config)
  //       .then((resn) => {
  //         setUserName(resn.data.data[0].full_name);
  //         setLoginUserId(resn.data.data[0].sk_user_id);
  //         setEmail(resn.data.data[0].email);
  //         console.log(resn.data.data[0].sk_user_id);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  // };


  useEffect(() => {
    getData();
    // getUserDeatils();
  }, []);

  return (
    <>
      {/* {showMessage && (
        <Message
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          setPageIDValue={setPageIDValue}
          page_idValue={page_idValue}
          userId={userId}
          loginUserId={loginUserId}
          userName={userName}
          email={email}
        />
      )} */}
      {loginPopup && (
        <Login loginPopup={loginPopup} setLoginPopup={setLoginPopup} />
      )}

      {arrayData.map((value, index) => {
        return (
          <>
            {name === value._id && (
              <Container className='home-container px-0' key={index}>
                <div className=''>
                  <Card className='brand-page'>
                    <div className='brand-banner'>
                      <img
                        id='brand-banner'
                        src={`https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=${value.brand_banner}`}
                      />
                    </div>

                    <div className='middel-div-sub d-flex
                    flex-column align-items-center'>
                      <div className='brand-logo logo-brand'>
                        <Card.Img
                          className='bg-white'
                          src={`https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=${value.brand_logo}`}
                          alt={value.brand_name}
                        />
                        <div>
                          <h3 className='text-center mt-3'>
                            {value.brand_name}
                          </h3>
                          {/* <span className='d-flex'>
                            <p id='sub-follower-inner'>
                              107 <span>Followers</span>
                            </p>
                            <p id='sub-follower-post'>
                              107 <span>Posts</span>
                            </p>
                          </span> */}
                        </div>
                      </div>
                      {/* <div
                        id='brand-btn'
                        className='w-100 d-flex justify-content-evenly mt-3'
                      >
                        <Button className='brand-btn follow-btn px-3 py-2'>
                          Follow
                        </Button>
                        <Button className='brand-btn msg-btn px-3 py-2'>
                          Message
                        </Button>
                      </div> */}
                    </div>

                    <div className='flex-container border-bottom'>
                      <p>Home</p>
                      {/* <p>Products</p> */}
                    </div>
                  </Card>
                  <div className='second-brand-div'>
                    <span className='left-section'>
                      <BrandArticle id={value._id} />
                    </span>
                    <span className='right-section'>
                      <Card className='brand-right-card'>
                        <div className='second-card-heading'>
                          <div className='second-card-inner1'>
                            <label htmlFor=''>Photos</label>
                          </div>
                          <div className='second-card-inner'>
                            {/* <label className='see-all' htmlFor=''>
                            See All
                          </label> */}
                          </div>
                        </div>
                        <BrandPhotos id={value._id} />
                      </Card>
                      <Card className='brand-right-card'>
                        <div className='second-card-heading'>
                          <div className='second-card-inner1'>
                            <label htmlFor=''>Products</label>
                          </div>
                          <div className='second-card-inner'>
                            {/* <label className='see-all' htmlFor=''>
                            See All
                          </label> */}
                          </div>
                        </div>
                        <BrandsProducts id={value._id} />
                      </Card>
                    </span>
                  </div>
                </div>
              </Container>
            )}
          </>
        );
      })}
    </>
  );
};

export default Brands;
