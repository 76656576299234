import React from 'react';

const Footer = () => {
  return (
    <>
      <footer>
        <div className='ly_footer_inner'>
          <small className='el_footerCopyright'>
            Copyright © 2014-2023 Mykids Ventures Private Limited. 1.0.5
          </small>
        </div>
      </footer>
    </>
  );
};

export default Footer;
