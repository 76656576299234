import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './screen/Home';
import Categories from './screen/Categories';
import SubCategories from './screen/SubCategories';
import Brands from './screen/Brands';
import {Routes, Route} from 'react-router-dom';
import Footer from './components/Footer';
import SeeAllBrandPage from './screen/SeeAllBrandPage';
import Login from './screen/Login';
import React, {useState} from 'react';
import Forget from './screen/Forget';
import Reset from './screen/Reset';

function App() {
  const [loginPopup, setLoginPopup] = useState(false);
  const [forgetShow, setForgetShow] = useState(false);
  const [showReset, setShowReset]=useState(false);
  return (
    <>
      <Header />
      <Routes basename='/'>
        <Route path='/' element={<Home />} />
        <Route path='/categories/:name' element={<Categories />} />
        <Route path='/subcategories/:name' element={<SubCategories />} />
        <Route path='/brands/:name' element={<Brands />} />
        <Route path='/brands' element={<SeeAllBrandPage />} />
      </Routes>
      <Login open={loginPopup} onClose={() => setLoginPopup(false)} />
      <Forget open={forgetShow} onClose={() => setForgetShow(false)}/>
      <Reset open={showReset} onClose={() => setShowReset(false)}/>
      <Footer />
    </>
  );
}

export default App;
