import React from 'react';
import {useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import mobileBanner1 from '../assets/img/mobile-banner/banner1-M.jpg';
import mobileBanner2 from '../assets/img/mobile-banner/banner2-M.jpg';
import mobileBanner3 from '../assets/img/mobile-banner/banner3-M.jpg';
import pcBanner1 from '../assets/img/pc-banner/banner1-Pc.jpg';
import pcBanner2 from '../assets/img/pc-banner/banner2-Pc.jpg';
import pcBanner3 from '../assets/img/pc-banner/banner3-Pc.jpg';
import pcBanner4 from '../assets/img/pc-banner/banner4-Pc.jpg';
import pcBanner5 from '../assets/img/pc-banner/banner5-Pc.jpg';

const pcBanner = [pcBanner1, pcBanner2, pcBanner3, pcBanner4, pcBanner5];
const mobileBanner = [mobileBanner1, mobileBanner2, mobileBanner3];

const ImageCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const getImageFolder = () => {
    const screenWidth = window.innerWidth;
    return screenWidth < 768 ? 'mobileBanner' : 'pcBanner';
  };

  const imageFolder = getImageFolder();
  let carouselItems;
  if (imageFolder == 'mobileBanner') {
    carouselItems = mobileBanner.map((image, key) => (
      <div className='carousel-item' key={key}>
        <img className='d-block w-100' src={image} alt={`banner`} />
      </div>
    ));
  } else {
    carouselItems = pcBanner.map((image, key) => (
      <div className='carousel-item' key={key}>
        <img className='d-block w-100' src={image} alt={`banner`} />
      </div>
    ));
  }

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}
      indicators={false} interval={2000}>
      {carouselItems}
    </Carousel>
  );
};

export default ImageCarousel;
