import React, {useState, useEffect} from 'react';
import ImageCarousel from '../components/Banners.js';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import '../assets/css/desktop.css';
import {Link} from 'react-router-dom';
import BrandApi from '../utils/brandapi.js';

const SubCategories = () => {
  // const navigate = useNavigate();
  // const [index, setIndex] = useState(0);
  const [arrayData, setArrayData] = useState([]);

  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };


  const getData = async () => {
    await BrandApi.getPagesAll()
        .then((response) => {
          setArrayData(response.data);
        })
        .catch((error) => {
          console.log('API error:', error);
        });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='container home-container px-0'>
      <Card className='first-card'>
        <ImageCarousel />
      </Card>

      <Card className='second-card'>
        <div className='second-card-heading'>
          <div className='second-card-inner1'>
            <label htmlFor=''>Brand For You</label>
          </div>
          <div className='second-card-inner'>
            <label className='see-all' htmlFor=''>See All</label>
          </div>
        </div>

        <CardGroup border='0'>
          {arrayData?.map((value, index) => {
            return (
              <>
                {value.page_status == 'active' && (
                  <Card
                    id='el-four'
                    border='0'
                    className='card-group-inner-home text-center'
                    key={index}
                  >
                    <Link to={`/Brands/${value.page_id}`}>
                      <div className='home-card-img-second'>
                        <Card.Img
                          src={`https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=${value.brand_logo}`}
                          alt={value.brand_name}
                          className='second-img-inner'
                        />
                        <small>{value.brand_name}</small>
                      </div>
                    </Link>
                  </Card>
                )}
              </>
            );
          })}
        </CardGroup>
      </Card>
    </div>
  );
};

export default SubCategories;
