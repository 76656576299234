import React from 'react';
import Card from 'react-bootstrap/Card';

const obj = [
  {
    link: 'https://www.beingtheparent.com/skin-care-products-during-buying-guide/',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2022/02/Skincare-during-pregnancy-Buying-Guide.png',
    title: 'How to Choose Skincare Products During Pregnancy',
    text: `During Pregnancy, it is common for your skin to experience
        changes. Your dry skin might altogether turn oily overnight or
        might get super sensitive. Gentle cleansers are your best bet
        in such conditions. It is important to know how to choose
        skincare products during pregnancy.`,
  },
  {
    link: 'https://www.beingtheparent.com/nursing-tops-for-pregnant-women/',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2022/02/Nursing-Tops-For-Pregnant-Women.jpg',
    title: 'Nursing Tops For Pregnant Women',
    text: `Nursing tops are an important part of your pregnancy and
        breastfeeding apparel. Gone are those days when it was
        difficult to breastfeed a baby publicly. There have been many
        unceasing inventions in clothing that favour mothers of the
        current generation. One of the most useful and fulfilling
        among those is pregnancy wear and nursing tops. Nursing tops
        for pregnant women are increasingly being used as comfort
        wear, especially by those dealing with painful breasts and
        leaky breasts during pregnancy`,
  },
  {
    link: 'https://www.beingtheparent.com/best-pregnancy-pillows-in-india-for-a-good-nights-sleep/',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2022/01/Pregnancy-Pillow.jpg',
    title: '5 Best Pregnancy Pillows in India for a Good Night’s Sleep',
    text: `Pregnancy pillows, also known as pregnancy body pillows, are
        the best aid to sleep while pregnant. A pregnancy pillow helps
        to support your back and make you feel more comfortable while
        sleeping. You can use a pregnancy pillow in bed during the
        first few months of your pregnancy, but only if satisfied with
        it. Pregnancy pillows are the sleeping solution for modern
        women.`,
  },
  {
    link: 'https://www.beingtheparent.com/best-baby-milk-powders-in-india/',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2021/12/Nestle-Lactogen-1-Infant-Formula-Powder.jpg',
    title: 'Best Baby Milk Powders In India',
    text: `One of the most difficult aspects of raising a 
    baby is taking care of their health and nutrition.
     The baby’s development is crucial in the 
     first two years and parents must pay special attention to their nutrition. 
     Mother’s milk is the best source of nutrition for a baby.
      Although, there are other options too, 
      one of which we are going to review is 
      the baby milk powders. Also known as baby formula, 
      they are equally beneficial, and you can 
      feed your baby any time and anywhere you want. 
      There are many baby milk brands on the market. 
      To give a clear view of the best baby milk powders, 
      we will review the best brands in detail.`,
  },
];

const RelatedArticle = () => {
  return (
    <Card className='second-card border-bottom'>
      <div className='second-card-heading'>
        <div className='second-card-inner1'>
          <label htmlFor=''>Related Article</label>
        </div>
        <div className='second-card-inner'>
          {/* <label className='see-all' htmlFor=''>See All</label> */}
        </div>
      </div>

      <div className=''>
        <div className='scroll-row'>
          {obj.map((obj, index) => {
            return (
              <div className='article-card' key={index}>
                <a href={obj.link} target='_blank' rel='noreferrer'>
                  <img src={obj.image} alt={obj.title} />
                  <p className='article-p-heading'>{obj.title}</p>
                  <p className='article-card-text'>{obj.text}</p>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default RelatedArticle;
