import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';
import BrandApi from '../utils/brandapi';

const BrandsForYou = () => {
  const navigate = useNavigate();
  const [arrayData, setArrayData] = useState([]);
  const getData = async () => {
    await BrandApi.getPagesAll()
        .then((response) => {
          setArrayData(response.data);
        })
        .catch((error) => {
          console.log('API error:', error);
        });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSeeAllBrands = () => {
    navigate('/brands');
  };

  return (
    <Card className='second-card d-flex flex-row flex-wrap border-bottom'>
      <div className='second-card-heading'>
        <div className='second-card-inner1'>
          <label htmlFor=''>Brands For You</label>
        </div>
        <div className='second-card-inner'>
          <label htmlFor='' className='see-all btn btn-link' onClick={handleSeeAllBrands}>
            See All
          </label>
        </div>
      </div>
      {arrayData?.map((value, index) => {
        return (
          <Card border='0' className='mt-4 col-4 col-md-3 col-lg-2 brand-card'
            key={index}>
            <Link
              to={`/brands/${value._id}`}
              className='d-flex flex-column align-items-center'
            >
              <Card.Img
                src={`https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=${value.brand_logo}`}
                className='second-img-inner'
              />
              <small className=''>{value.brand_name}</small>
            </Link>
          </Card>
        );
      })}
    </Card>
  );
};

export default BrandsForYou;
