import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/svg/logo.svg';
import {useNavigate} from 'react-router-dom';
import Login from '../screen/Login';
import Forget from '../screen/Forget';
import Reset from '../screen/Reset';

const Header = () => {
  const [loginPopup, setLoginPopup] = useState(false);
  const [forgetShow, setForgetShow] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const navigate = useNavigate();
  const handleClickHome = () => {
    navigate('/');
  };

  const handleClickLogin = () => {
    setLoginPopup(true);
  };


  const handleLogout = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.reload(false);
  };

  const username = sessionStorage.getItem('username');
  return (
    <>
      {loginPopup && (
        <Login
          loginPopup={loginPopup}
          setLoginPopup={setLoginPopup}
          setForgetShow={setForgetShow}
          forgetShow={forgetShow}
          setShowReset={setShowReset}
        />
      )}

      {forgetShow && (
        <Forget
          setForgetShow={setForgetShow}
          forgetShow={forgetShow}
          setShowReset={setShowReset}
        />
      )}

      {showReset && (
        <Reset
          setShowReset={setShowReset}
          showReset={showReset}
          setLoginPopup={setLoginPopup}
        />
      )}
      <Navbar expand='lg' className='mb-2 bg-white sticky-top py-3'>
        <Container className='justify-content-between'>
          <Navbar.Brand style={{cursor: 'pointer'}}>
            <img src={Logo} alt='brandlabs' onClick={handleClickHome} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav'
            style={{border: '0 !important'}}/>
          <Navbar.Collapse id='basic-navbar-nav'
            className='justify-content-end'>
            <Nav className='ml-auto align-items-center'>
              <Nav.Link className='mr-2'>
                <label>{sessionStorage.getItem('username')}</label>
              </Nav.Link>
              {username == '' || username == null ? (
                <Nav.Link className=''>
                  <button className='brand-login' onClick={handleClickLogin}>
                    Login
                  </button>
                </Nav.Link>
              ) : (
                <Nav.Link className=''>
                  <button className='brand-login' onClick={handleLogout}>
                    Logout
                  </button>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
