import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import BrandApi from '../utils/brandapi';

const BrandsProducts = (prop) => {
  const [productsData, setproductData] = useState([]);
  const productPages = async () => {
    await BrandApi.getProduct(prop.id)
        .then((response) => {
          setproductData(response.products);
        })
        .catch((error) => {
          console.log('API error:', error);
        });
  };

  useEffect(() => {
    productPages();
  }, []);

  return (
    <div className='inner-right products-sec scroll-row'>
      {productsData.map((product) => {
        return (
          <>
            <div className='product-div scroll-card'>
              <div className='product-div-left'>
                <Card.Img
                  id='product-img'
                  variant='top'
                  src={`https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=${product.product_logo}`}
                  alt={product.product_logo}
                />
              </div>
              <div className='product-div-right'>
                <h6>{product.product_name}</h6>
                <p id='product_desc'>{product.product_introduction}</p>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default BrandsProducts;
