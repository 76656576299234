import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import brandApi from '../utils/brandapi';


const BrandPhotos = (prop) => {
  const [productsData, setproductData] = useState([]);

  const productPages = async () => {
    await brandApi.getProduct(prop.id)
        .then((response) => {
          setproductData(response.products);
        })
        .catch((error) => {
          console.log('API error:', error);
        });
  };


  useEffect(() => {
    productPages();
  }, []);

  return (
    <div className='inner-right mt-4 d-flex flex-wrap justify-content-center'>
      {productsData.map((product) => {
        return (
          <>
            <div className='brand-photos' key={product.product_logo}>
              <Card.Img
                id='other-img'
                variant='top'
                src={`https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=${product.product_logo}`}
                alt={product.product_logo}
              />
            </div>
          </>
        );
      })}
    </div>
  );
};

export default BrandPhotos;
