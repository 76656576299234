import React from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import '../assets/css/desktop.css';
import Pregnant from '../assets/svg/preLady.svg';
import Lady1 from '../assets/svg/lady1.svg';
import Jewe from '../assets/svg/jewelery.svg';
import Lady2 from '../assets/svg/lady2.svg';
import ProductImg from '../assets/svg/adver.svg';
import {Link} from 'react-router-dom';
import ImageCarousel from '../components/Banners.js';

const Categories = () => {
  return (
    <div className='container px-0 home-container'>
      <Card className='first-card'>
        <ImageCarousel />

        <div className='home-cat'>
          <label htmlFor=''>Sub-categories in Pregnancy Clothing</label>
        </div>

        <CardGroup border='0'>
          <div className='article-card-sub-category '>
            <Link to='/subcategories/MaternityLingerie'>
              <div border='0'>
                <Card.Img src={Pregnant} className='sub-cat-img' />
                <small className='article-p-heading'>Maternity Lingerie</small>
              </div>
            </Link>
          </div>
          <div className='article-card-sub-category '>
            <Link to='/subcategories/MaternityTop&Bottom'>
              <div border='0'>
                <Card.Img src={Lady1} className='sub-cat-img' />

                <small className='article-p-heading'>
                  Maternity Top & Bottom
                </small>
              </div>
            </Link>
          </div>
          <div className='article-card-sub-category '>
            <Link to='/subcategories/Jewellery'>
              <div border='0'>
                <Card.Img src={Jewe} className='sub-cat-img' />
                <small className='article-p-heading'>Jewellery</small>
              </div>
            </Link>
          </div>

          <div className='article-card-sub-category '>
            <Link to='/subcategories/Jewellery'>
              <div border='0'>
                <Card.Img src={Lady2} className='sub-cat-img' />
                <small className='article-p-heading'>Jewellery</small>
              </div>
            </Link>
          </div>
        </CardGroup>
      </Card>

      <Card className='second-card'>
        <div className='second-card-heading'>
          <div className='second-card-inner1'>
            <label htmlFor=''>Best in Body Care</label>
          </div>
          <div className='second-card-inner'>
            <label htmlFor className='see-all'>See All</label>
          </div>
        </div>
        <div className=''>
          <div className='scroll-row'>
            {[1, 2, 3, 4, 5, 6, 7].map((index) => {
              return (
                <div className='product-card col-lg-3' key={index}>
                  <img src={ProductImg} alt='...' className='product-img' />
                  <p className='product-p-heading'>Upto 30% off </p>
                  <p className='product-card-text'>On entire range</p>
                </div>
              );
            })}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Categories;
