import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';

const obj = [
  {
    title: 'Nipple Shields When Breastfeeding – When, Why and How to Use',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2022/10/nipple-shields.jpg',
    link: 'https://www.beingtheparent.com/nipple-shield-when-breastfeeding-when-why-and-how-to-use/',
  },
  {
    title: 'How to Choose Moisturizer During Pregnancy?',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2022/04/Moisturizer-during-prengnancy.jpg',
    link: 'https://www.beingtheparent.com/moisturizer-during-pregnancy/',
  },
  {
    title: 'How To Choose Stretchable Tops During Pregnancy',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2022/04/Stretchable-pregnancy-tops-during-pregnancy-1.jpg',
    link: 'https://www.beingtheparent.com/how-to-choose-stretchable-tops-during-pregnancy/',
  },
  {
    title: 'How To Choose Nipple Creams During Pregnancy',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2022/04/Nipple-Creams-during-pregnancy.jpg',
    link: 'https://www.beingtheparent.com/nipple-creams-during-pregnancy/',
  },
  {
    title: 'How to choose Antacid Chewable Tablets',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2022/03/Antacid-during-pregnancy.jpg',
    link: 'https://www.beingtheparent.com/how-to-choose-antacid-chewable-tablets/',
  },
  {
    title: 'How to choose pregnancy shoes during pregnancy',
    image:
      'https://www.beingtheparent.com/wp-content/uploads/2022/02/Pregnancy-shoes.jpg',
    link: 'https://www.beingtheparent.com/how-to-choose-pregnancy-shoes-during-pregnancy/',
  },
];

const BuyingGuide = () => {
  return (
    <Card className='second-card border-bottom'>
      <div className='second-card-heading'>
        <div className='second-card-inner1'>
          <label htmlFor=''>Buying Guides</label>
        </div>
        <div className='second-card-inner'>
          {/* <label className='see-all' htmlFor=''>See All</label> */}
        </div>
      </div>
      <div className='scroll-row'>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => {
          return (
            <Badge type='button' className='scroll-card'
              bg='secondary' key={index}>
              Baby Massage Oil
            </Badge>
          );
        })}
      </div>
      <div className='scroll-row'>
        {obj.map((obj, index) => {
          return (
            <div className='scroll-card' key={index}>
              <a href={obj.link} target='_blank' rel='noreferrer'>
                <img src={obj.image} alt={obj.title} />
                <p className='scroll-card-text mt-3'>{obj.title}</p>
              </a>
            </div>
          );
        },
        )}
      </div>
    </Card>
  );
};

export default BuyingGuide;
