import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';
import BrandApi from '../utils/brandapi.js';

const SeeAllBrandPage = () => {
  const [arrayData, setArrayData] = useState([]);
  const getData = async () => {
    await BrandApi.getPagesAll()
        .then((response) => {
          setArrayData(response.data);
        })
        .catch((error) => {
          console.log('API error:', error);
        });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='container home-container px-0'>
      <Card className='seeall-inner'>
        <div className='second-card-inner1'>
          <label htmlFor=''>Brand For You</label>
        </div>
        <br />
        <div className='see_brands'>
          {arrayData?.map((value, index) => {
            return (
              <>
                {value.page_status === 'active' && (
                  <Card
                    id='el-four'
                    border='0'
                    className='card-group-inner-home text-center'
                    key={index}
                  >
                    <Link to={`/brands/${value._id}`}>
                      <div className='see_brand_card'>
                        <img
                          src={`https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=${value.brand_logo}`}
                          alt={value.brand_name}
                        />
                        <small>{value.brand_name}</small>
                      </div>
                    </Link>
                  </Card>
                )}
              </>
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default SeeAllBrandPage;
