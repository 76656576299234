import React, {useState} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Google from '../assets/svg/Googlesvgicon.svg';
import {useGoogleLogin} from '@react-oauth/google';
import BrandApi from '../utils/brandapi.js';
const Login = (prop) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  // api validation
  const handleLogin = async (e) => {
    e.preventDefault();
    const data = JSON.stringify({
      username: formData.username,
      password: formData.password,
    });
    const form = e.currentTarget;
    if (
      form.checkValidity() === false ||
      formData.username === '' ||
      formData.password === ''
    ) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setLoader(true);
      await BrandApi.userSignIn(data)
          .then(function(response) {
            sessionStorage.setItem('accessToken',
                response.data.data.Accesstoken);
            const token = response.data.data.Accesstoken;
            const username = formData.username;
            if (response.data.status === true) {
              setLoader(false);
              window.location.reload(false);
              sessionStorage.setItem('accessToken', token);
              sessionStorage.setItem('username', username);
              prop.setLoginPopup(false);
              Swal.fire({
                icon: 'success',
                title: 'Welcome Back!',
                showConfirmButton: false,
                timer: 1000,
              });
              prop.setLoginPopup(false);

              if (window.location.pathname == '/') {
                navigate('/');
              } else if (window.location.pathname == '/categories/:name') {
                navigate('//categories/:name');
              } else if (window.location.pathname == '/subcategories/:name') {
                navigate('/categories/:name');
              } else if (window.location.pathname == '/brand/:name') {
                navigate('/brand/:name');
              }
            } else if (response.data.status === false) {
            // navigate('/')
              setLoader(false);
              Swal.fire({
                icon: 'error',
                text: response.data.message,
                showConfirmButton: true,
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.data.message,
                showConfirmButton: false,
              });
            }
          })
          .catch(function(error) {
            console.log(error);
          });
    }
    setValidated(true);
  };

  const loginwithGoogle = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
            'https://www.googleapis.com/oauth2/v3/userinfo',
            {
              headers: {
                Authorization: `Bearer ${respose.access_token}`,
              },
            },
        );
        const data = JSON.stringify({
          email: res.data.email,
          c_code: '',
          mobile: '',
          country: '101',
          pincode: '583 400',
          signupsource: '',
          due_date_parent: '',
          dob: '',
          full_name: res.data.given_name,
          alies_name: '',
          gender: 'Female',
          i_am: '2',
          password: '',
          registred_from: '',
          no_of_children: '0',
          username_momnexus: '',
          childata: [],
          firebase_device_id: process.env.REACT_APP_Global_Token_Firbase,
        });
        await BrandApi.userRegister(data)
            .then(function(response) {
              if (response.data.status === true) {
                sessionStorage.setItem(
                    'accessToken',
                    response.data.data.Accesstoken,
                );
                sessionStorage.setItem('username', res.data.email);
                sessionStorage.setItem('given_name', res.data.given_name);
                prop.setLoginPopup(false);
                Swal.fire({
                  icon: 'success',
                  title: 'Welcome Back!',
                  showConfirmButton: false,
                  timer: 1000,
                });
                prop.setLoginPopup(false);
                if (window.location.pathname == '/') {
                  navigate('/');
                } else if (window.location.pathname == '/categories/:name') {
                  navigate('//categories/:name');
                } else if (window.location.pathname == '/subcategories/:name') {
                  navigate('/categories/:name');
                } else if (window.location.pathname == '/brand/:name') {
                  navigate('/brand/:name');
                }
              } else if (response.data.status === false) {
                navigate('/');
                setLoader(false);
                Swal.fire({
                  icon: 'error',
                  // eslint-disable-next-line max-len
                  text: 'Your account is not registered please create your account .',
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: response.data.message,
                  showConfirmButton: false,
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
      } catch (err) {
        console.log(err);
      }
    },
  });


  const handleForgetPassowrd=()=>{
    prop.setForgetShow(true);
    prop.setLoginPopup(false);
  };

  return (
    <>
      {loader ? (
        <div className='text-center vackground'>
          <div className='loader'
            ng-hide='data.length > 0'></div>
        </div>
      ) : (
        <Modal
          autoComplete='off'
          backdrop='static'
          show={prop.loginPopup}
          onHide={() => {
            prop.setLoginPopup(false);
          }}
        >
          <div>
            <Modal.Header closeButton className='border-0'>
              <Modal.Title>Login </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modalBody'>
              <Form
                autoComplete='off'
                noValidate
                validated={validated}
                onSubmit={handleLogin}
              >
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Control
                    type='email'
                    placeholder='Email'
                    autoFocus
                    id='username'
                    name='username'
                    value={formData.username}
                    onChange={handleChange}
                    autoComplete='off'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please fill the username.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Control
                    type='password'
                    placeholder='Password'
                    id='password'
                    name='password'
                    value={formData.password}
                    onChange={handleChange}
                    autoComplete='off'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please fill the password.
                  </Form.Control.Feedback>
                </Form.Group>

                <button onClick={handleForgetPassowrd} id='for'>
                  <p >Forgot Password?</p>
                </button>

                <button type='submit' className='col-12 btn-popup'>
                  Sign in
                </button>
              </Form>
              <p className='text-center or_option'>or</p>
              <Button
                className='col-12 withGoogle '
                onClick={loginwithGoogle}
              >
                <img src={Google} alt='' className='googleFont' /> Login With
                  Google
              </Button>

              <p className='text-center haveAccount'>
                  Don’t have an account?{' '}
                <a href={`https://beingtheparent.com/signup/?redirect=${window.location.href.slice(8)}`}className='text-danger signup_account' target='_blank' rel='noreferrer'>
                    Signup
                </a>
              </p>

            </Modal.Body>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Login;
