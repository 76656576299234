import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import BrandApi from '../utils/brandapi';

const BrandArticle = (prop) => {
  const [arraySectionData, setArraySectionData]=useState([]);
  const getSection = async () => {
    await BrandApi.getSection(prop.id)
        .then((response) => {
          setArraySectionData(response.data);
        })
        .catch((error) => {
          console.log('API error:', error);
        });
  };

  useEffect(()=>{
    getSection();
  }, []);

  return (
    <Card className='second-card border-bottom'>
      <div className='second-card-heading'>
        <div className='second-card-inner1'>
          <label htmlFor=''>Categories</label>
        </div>
      </div>
      <div className=''>
        <div className='d-flex flex-column'>
          {arraySectionData.map((obj, key) => {
            return (
              <div className='brand-feed' key={key}>
                <a href={obj.link} target='_blank' rel='noreferrer'>
                  <hr />
                  <img src={`https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=${obj.section_media}`} alt='' />
                  <p className='article-p-heading'>{obj.section_title}</p>
                  <p className='article-card-text'>{obj.section_description}</p>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default BrandArticle;
